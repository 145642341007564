var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{ref:"table",attrs:{"module":"tickets","title":"Posizioni","headers":_vm.headers,"exportable":false,"actions":_vm.actions,"sortby":"updated_at","show-select":_vm.showSelect,"item-key":"id","items-per-page-options":[10, 25, 50, 100]},on:{"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"item.external_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.external_id))])]}},{key:"item.effective_priority",fn:function(ref){
var item = ref.item;
return [(item.effective_priority >= 15)?_c('v-chip',{attrs:{"small":"","label":"","color":"red"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-decagram-outline")]),_vm._v(" ALTA ")],1):(
              item.effective_priority > 10 && item.effective_priority < 15
            )?_c('v-chip',{attrs:{"small":"","label":"","color":"orange"}},[_vm._v(" MEDIA ")]):_c('v-chip',{attrs:{"label":"","color":"info","small":""}},[_vm._v("Normale")])]}},{key:"item.activity",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.activity.macro)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(item.activity.category)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(item.activity.subcategory)+" ")]}},{key:"item.customers_user",fn:function(ref){
            var item = ref.item;
return [(item.customers_user)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("get")(item,['customers_user', 'last_name'], ''))+" "+_vm._s(_vm._f("get")(item,['customers_user', 'first_name'], ''))+" ")]),_c('br'),_c('small',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("get")(item,['customers_user', 'mobile_phone_number'], ''))+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"item.tobe_managed_by",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.sla_1)?_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" La lavorazione non è stata presa in carico entro lo SLA previsto. ")])]),_c('span',{class:{ 'font-weight-bold': item.sla_1 }},[_vm._v(" "+_vm._s(_vm._f("momentOr")(item.tobe_managed_by,'DD/MM/YYYY HH:mm', '-'))+" ")])]}},{key:"item.tobe_closed_by",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.sla_2)?_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" La lavorazione non è stata chiusa entro lo SLA previsto. ")])]),_c('span',{class:{ 'font-weight-bold': item.sla_2 }},[_vm._v(" "+_vm._s(_vm._f("momentOr")(item.tobe_closed_by,'DD/MM/YYYY HH:mm', '-'))+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"my-6",attrs:{"small":"","color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }