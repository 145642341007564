<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="external_id" name="Identificativo" dense
        :xl="2"
      />

      <FormCustomers
        v-model="customer_id"
        :customer_id="customer_id"
        name="Debitore"
        dense
        clearable
        hint="Cerca per nominativo, CF, email o numero di telefono"
        persistent-hint
      />

      <FormItem
        v-model="country"
        :values="countries"
        type="autocomplete"
        name="Nazione"
        chips
        deletable-chips
        dense
      />

    </v-row>
    <v-row dense>
      <FormItem
        v-model="macro"
        :values="
          macrosByContext({
            customerId: customer_id,
            category,
            subcategory,
          })
        "
        type="autocomplete"
        name="Cliente"
        chips
        deletable-chips
        dense
      />

      <FormItem
        v-model="category"
        :values="
          categoriesByContext({
            customerId: customer_id,
            macro,
            subcategory,
          })
        "
        type="autocomplete"
        name="Gruppo"
        chips
        deletable-chips
        dense
      />
      <FormItem
        v-model="subcategory"
        :values="
          subcategoriesByContext({
            customerId: customer_id,
            macro,
            category,
          })
        "
        type="autocomplete"
        name="Portfolio"
        chips
        deletable-chips
        dense
      />


      <FormUsers
        v-model="managed_by"
        label="Operatore"
        :use-username="true"
        dense
        clearable
        :additional-filters="{ role_id: [1,2,5,6] }"
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="status"
        name="Stato"
        type="autocomplete"
        chips
        deletable-chips
        dense
        :values="statusesByContext({ macro, category, resolution })"
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="resolution"
        name="Esito"
        type="autocomplete"
        chips
        deletable-chips
        dense
        :values="resolutionsByContext({ macro, category, status })"
        :xl="3"
      />


      <FormItem
        v-model="created_at"
        type="datepicker"
        name="Data Inserimento"
        hint="Ricerca per Data Inserimento"
        dense
        range
        :xl="2"
      />
      <FormItem
        v-model="updated_at"
        type="datepicker"
        name="Data Aggiornamento"
        hint="Ricerca per Data Aggiornamento"
        dense
        range
        :xl="2"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormUsers from '@components/forms/FormUsers.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'tickets/getFiltersFields',
  mutationType: 'tickets/SET_FILTER_FIELDS',
})

export default {
  name: 'TicketsSectionSearch',
  components: {
    FormCustomers,
    FormUsers,
    FormItem,
    BaseSectionSearch,
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'anomaly',
      'assigned_at',
      'category',
      'channel',
      'closed_at',
      'created_at',
      'updated_at',
      'customer_id',
      'customer_user_id',
      'date_appointment',
      'external_id',
      'kasko',
      'macro',
      'managed_at',
      'managed_by',
      'resolution',
      'serial_imei',
      'solicited_at',
      'status',
      'subcategory',
      'tobe_closed_by',
      'tobe_managed_by',
      'type',
      'country'
    ]),
    ...mapState('ticketsActivities', ['types']),
    ...mapState('customers', ['countries']),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
      subcategoriesByContext: 'subcategoriesByContext',
    }),
    ...mapState('tickets', ['anomalies']),
    ...mapGetters('ticketsStatuses', {
      statusesByContext: 'statusesByContext',
    }),
    ...mapGetters('ticketsResolutions', {
      resolutionsByContext: 'resolutionsByContext',
    }),
  },
  async mounted() {
    await this.getDropdownList({})
  },
  methods: {
    ...mapActions('ticketsActivities', ['getDropdownList']),
    ...mapMutations('tickets', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>
