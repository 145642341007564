var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSectionSearch',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"name":"Identificativo","dense":"","xl":2},model:{value:(_vm.external_id),callback:function ($$v) {_vm.external_id=$$v},expression:"external_id"}}),_c('FormCustomers',{attrs:{"customer_id":_vm.customer_id,"name":"Debitore","dense":"","clearable":"","hint":"Cerca per nominativo, CF, email o numero di telefono","persistent-hint":""},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}}),_c('FormItem',{attrs:{"values":_vm.countries,"type":"autocomplete","name":"Nazione","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"values":_vm.macrosByContext({
          customerId: _vm.customer_id,
          category: _vm.category,
          subcategory: _vm.subcategory,
        }),"type":"autocomplete","name":"Cliente","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.macro),callback:function ($$v) {_vm.macro=$$v},expression:"macro"}}),_c('FormItem',{attrs:{"values":_vm.categoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          subcategory: _vm.subcategory,
        }),"type":"autocomplete","name":"Gruppo","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('FormItem',{attrs:{"values":_vm.subcategoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          category: _vm.category,
        }),"type":"autocomplete","name":"Portfolio","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.subcategory),callback:function ($$v) {_vm.subcategory=$$v},expression:"subcategory"}}),_c('FormUsers',{attrs:{"label":"Operatore","use-username":true,"dense":"","clearable":"","additional-filters":{ role_id: [1,2,5,6] }},model:{value:(_vm.managed_by),callback:function ($$v) {_vm.managed_by=$$v},expression:"managed_by"}}),(!_vm.currentCustomerId)?_c('FormItem',{attrs:{"name":"Stato","type":"autocomplete","chips":"","deletable-chips":"","dense":"","values":_vm.statusesByContext({ macro: _vm.macro, category: _vm.category, resolution: _vm.resolution })},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}):_vm._e(),(!_vm.currentCustomerId)?_c('FormItem',{attrs:{"name":"Esito","type":"autocomplete","chips":"","deletable-chips":"","dense":"","values":_vm.resolutionsByContext({ macro: _vm.macro, category: _vm.category, status: _vm.status }),"xl":3},model:{value:(_vm.resolution),callback:function ($$v) {_vm.resolution=$$v},expression:"resolution"}}):_vm._e(),_c('FormItem',{attrs:{"type":"datepicker","name":"Data Inserimento","hint":"Ricerca per Data Inserimento","dense":"","range":"","xl":2},model:{value:(_vm.created_at),callback:function ($$v) {_vm.created_at=$$v},expression:"created_at"}}),_c('FormItem',{attrs:{"type":"datepicker","name":"Data Aggiornamento","hint":"Ricerca per Data Aggiornamento","dense":"","range":"","xl":2},model:{value:(_vm.updated_at),callback:function ($$v) {_vm.updated_at=$$v},expression:"updated_at"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }