<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-autocomplete
        v-model="select"
        :chips="multi"
        :clearable="clearable"
        :deletable-chips="multi"
        :dense="dense"
        :disabled="disabled"
        :error-messages="errors"
        :filter="customFilter"
        :hint="hint"
        :items="list"
        :label="label || name"
        :loading="loading"
        :item-value="useUsername ? 'username' : 'value'"
        :multiple="multi"
        :persistent-hint="persistentHint"
        :readonly="readonly"
        :search-input.sync="search"
        :small-chips="multi"
        hide-details
        filled
        @input="onInput"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Non è stato trovato nessun utente.
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ item.username }}</strong> &bull; {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { debounce, matchesProperty } from 'lodash'
import { customFilterFromProps } from '@/src/utils/helper-functions'

export default {
  name: 'FormUsers',
  components: {},
  props: {
    additionalFilters: {
      type: Object,
      required: false,
      default: function() {
        return {}
      },
    },
    clearable: {
      default: false,
      type: Boolean,
    },
    platform_user_id: {
      default: null,
      type: [String, Array],
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 3,
    },
    xl: {
      type: [String, Number],
      default: 2,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Utenti',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    useUsername: {
      type: [Boolean],
      default: false,
      required: false,
    },
    value: {
      default: null,
      type: [String, Array],
    },
    autoLoad: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    search: null,
    select: null,
  }),
  computed: {
    ...mapState('users', ['list', 'loading']),
  },
  watch: {
    platform_user_id: {
      immediate: true,
      handler: 'prefill',
    },
    value(val) {
      if (!val) this.prefill();
    },
    search(val) {
      if (val) {
        if (val !== this.select) {
          /**
           * The component emits a last search when the wanted element is clicked form the dropdown,
           * this prevents a search that would return an empty list
           */
          if (this.list.some(matchesProperty('text', val))) return
          // This prevents the component form ignoring changes injected from outside
          if (this.select !== this.value || !this.customer_id) {
            this.querySelections(val)
          }
        }
      } else this.querySelections(null);
    },
  },
  async mounted() {
    if (this.value) this.select = this.value;
    await this.searchUsers({
      filters: {
        ...this.additionalFilters,
      },
    })
    if (this.autoLoad) this.prefill()
  },
  beforeDestroy() {
    this.clearList()
  },
  methods: {
    ...mapActions('users', {
      searchUsers: 'getDropdownList',
    }),
    ...mapMutations('users', {
      clearList: 'RESET_STATS_DATA',
    }),
    customFilter: customFilterFromProps([
      'text',
      'username',
      'email',
      'mobile_phone_number',
    ]),
    async prefill() {
      if (this.platform_user_id) {
        await this.searchUsers({
          filters: {
            id: this.platform_user_id,
          },
        })
        this.select = this.multi
          ? [this.platform_user_id]
          : this.platform_user_id
      } else {
        this.clearList()
        this.select = this.multi ? [] : null
      }
    },
    async querySelections(query) {
      await this.searchUsers({
        filters: {
          query,
          // Flux inject filters
          ...this.additionalFilters,
        },
      })
    },
    onInput: debounce(function(value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
