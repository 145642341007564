<template>
  <BaseTable
    ref="table"
    v-model="selected"
    module="tickets"
    title="Posizioni"
    :headers="headers"
    :exportable=false
    :actions="actions"
    sortby="updated_at"
    :show-select="showSelect"
    item-key="id"
    :items-per-page-options="[10, 25, 50, 100]"
    @refresh="onRefresh"
  >
    <template v-slot:item.external_id="{ item }">
      <v-chip label>{{ item.external_id }}</v-chip>
    </template>

    <template v-slot:item.effective_priority="{ item }">
      <v-chip
        v-if="item.effective_priority >= 15"
        small
        label
        color="red"
      >
        <v-icon left>mdi-alert-decagram-outline</v-icon>
        ALTA
      </v-chip>
      <v-chip
        v-else-if="
                item.effective_priority > 10 && item.effective_priority < 15
              "
        small
        label
        color="orange"
      >
        MEDIA
      </v-chip>
      <v-chip v-else label color="info" small>Normale</v-chip>
    </template>
    <template v-slot:item.activity="{ item }">
      {{ item.activity.macro }}
      <v-icon small>mdi-chevron-right</v-icon>
      {{ item.activity.category }}
      <v-icon small>mdi-chevron-right</v-icon>
      {{ item.activity.subcategory }}
    </template>
    <template v-slot:item.customers_user="{ item }">
            <span v-if="item.customers_user">
              <strong>
                {{ item | get(['customers_user', 'last_name'], '') }}
                {{ item | get(['customers_user', 'first_name'], '') }}
              </strong>
              <br />
              <small class="text--secondary">
                {{ item | get(['customers_user', 'mobile_phone_number'], '') }}
              </small>
            </span>
      <span v-else> - </span>
    </template>

    <template v-slot:item.tobe_managed_by="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="item.sla_1"
            left
            color="red"
            small
            v-bind="attrs"
            v-on="on"
          >
            mdi-clock-alert
          </v-icon>
        </template>
        <span>
                La lavorazione non è stata presa in carico entro lo SLA
                previsto.
              </span>
      </v-tooltip>

      <span :class="{ 'font-weight-bold': item.sla_1 }">
              {{ item.tobe_managed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
            </span>
    </template>
    <template v-slot:item.tobe_closed_by="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="item.sla_2"
            left
            color="red"
            small
            v-bind="attrs"
            v-on="on"
          >
            mdi-clock-alert
          </v-icon>
        </template>
        <span>
                La lavorazione non è stata chiusa entro lo SLA previsto.
              </span>
      </v-tooltip>
      <span :class="{ 'font-weight-bold': item.sla_2 }">
              {{ item.tobe_closed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
            </span>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip small :color="getColor(item.status)" dark class="my-6">
        {{ item.status }}
      </v-chip>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import { mapActions, mapMutations } from 'vuex';
import { authComputed } from '@state/helpers.js'

export default {
  name: 'TicketListFiltered',
  components: { BaseTable },
  props: {
    itemsSelected: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actions: [
        {
          key: 'view',
          label: 'Apri Pratica',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          to: (item) => ({ name: 'tickets_detail', params: { id: item.id } }),
          onItemCondition: () => this.canUser('tickets', 'read'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    headers() {
      return [
        {
          text: '#ID',
          align: 'start',
          value: 'external_id',
        },

        ...(this.userType !== 'customer'
          ? [
            {
              text: 'Priorità',
              value: 'effective_priority',
            },
          ]
          : []),

        {
          text: 'Debitore',
          value: 'customer.common_name',
        },
        {
          text: 'Portfolio',
          align: 'start',
          value: 'activity',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Ultima azione',
          value: 'resolution',
        },
        {
          text: 'In Carico',
          value: 'managed_at',
        },
        {
          text: 'Operatore',
          value: 'managed_by',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },

        {
          text: 'Aggiornata',
          value: 'updated_at',
        },
      ]
    },
    selected: {
      get() {
        return this.itemsSelected;
      },
      set(newValue) {
        this.$emit('change-items-selected', newValue);
      },
    },
  },
  methods: {
    ...mapMutations('tickets', {
      setCtx: 'SET_CTX',
    }),
    ...mapActions('tickets', ['getItems']),
    getColor(status) {
      switch (status) {
        case 'waiting':
          return 'gray'
        case 'offline':
          return 'red'
        default:
          return 'green'
      }
    },
    onRefresh(ctx) {
      this.getItems(ctx);
      this.setCtx(ctx);
    },
  },
}
</script>

<style scoped>
</style>
